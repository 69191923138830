/**
 * Форматирует время и заполняет нужным количеством нулей
 * @param {number} time
 * @param {number} zeroesCount
 * @returns {string}
 */
export function formatTimePart(time: number, zeroesCount: number) {
  let requiredZeroesCount = zeroesCount - time.toString().length;

  if (requiredZeroesCount < 0) {
    requiredZeroesCount = 0;
  }

  return new Array(requiredZeroesCount).fill("0").join("") + time.toString();
}

/**
 * Форматирует время в формате 00:00.000
 * @param {number} time
 * @returns {string}
 */
export function formatTime(time: number) {
  let ms = time;
  const sMs = 1000;
  const mMs = sMs * 60;

  const m = Math.floor(ms / mMs);
  ms -= m * mMs;
  const s = Math.floor(ms / sMs);
  ms -= s * sMs;

  return (
    `${formatTimePart(m, 2)}:` +
    `${formatTimePart(s, 2)}.${formatTimePart(ms, 3)}`
  );
}

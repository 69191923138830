import { EColor } from "../components/Car/types";

export default function chooseColor(color: EColor): string[] {
  switch (color) {
    case EColor.green:
      return ["#3CA19D", "rgb(52, 108, 106)", "rgb(57, 149, 145)"];
      break;
    case EColor.red:
      return ["#FF766F", "rgb(176, 67, 62)", "rgb(217, 46, 38)"];
      break;
    case EColor.purple:
      return ["#9690EA", "rgb(78, 74, 139)", "rgb(131, 125, 217)"];
      break;
    case EColor.yellow:
      return ["#F3DB01", "rgb(176, 162, 40)", "rgb(225, 205, 28)"];
      break;
    default:
      return ["#75B0FF", "rgb(32, 78, 141)", "rgb(55, 113, 192)"];
  }
}

import React from "react";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import { styled } from "@material-ui/styles";
import { EColor } from "../Car/types";
import chooseColor from "../../utils/chooseColor";
import { Theme } from "../../theme/types";

const styles = makeStyles((theme: Theme) => ({
  root: {
    margin: "66px 171px 58.5px",
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: theme.typography.fontWeightMedium
  },
  container: {
    listStyle: "none",
    margin: 0,
    padding: 0,
    width: "100%",
    minHeight: "80px",
    display: "flex",
    justifyContent: "center"
  },
  playerInfo: {
    display: "flex",
    alignItems: "center",
    marginLeft: "30px",
    "&:first-child": {
      marginLeft: "0"
    }
  },
  playerName: {
    fontSize: theme.typography.titleFontSize,
    display: "flex",
    flexDirection: "column"
  }
}));

interface IPlayerNumberProps {
  color: string;
}
const PlayerInfoNumber = styled("span")<{}, IPlayerNumberProps>({
  fontSize: "80px",
  position: "relative",
  paddingRight: "30px",
  marginRight: "12px",
  paddingTop: "15px",
  "&:after": {
    content: "' '",
    display: "block",
    position: "absolute",
    top: "50%",
    right: "-3px",
    transform: "translateY(-50%)",
    backgroundColor: props => props.color,
    width: "25px",
    height: "5.5px"
  }
});

interface IPlayerInfoProps {
  data: {
    name: string;
    costumeColor: EColor;
  }[];
}
const PlayersInfo = (props: IPlayerInfoProps) => {
  const classes = styles();

  return (
    <div className={classes.root}>
      <ul className={classes.container}>
        {props.data.map(({ name, costumeColor }, i) => {
          const splitName = name.split(" ");
          console.log(splitName);

          return (
            <>
              <li
                key={i}
                className={classes.playerInfo}
                style={{ color: chooseColor(costumeColor)[0] }}
              >
                <PlayerInfoNumber key={i} color={chooseColor(costumeColor)[0]}>
                  {i + 1}
                </PlayerInfoNumber>
                <div className={classes.playerName}>
                  <span>{splitName[1]}</span>
                  <span>{splitName[0]}</span>
                </div>
              </li>
            </>
          );
        })}
      </ul>
    </div>
  );
};

export default PlayersInfo;

import React, { memo, useEffect } from "react";
import Game from "./Game";
import DummyPage from "../components/DummyPage";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";

import AppLoadingView from "./views/AppLoadingView";
import {
  ClosestLobbyQuery,
  closestLobbyQuery,
  LobbyLeaderboardQuery,
  lobbyLeaderboardQuery
} from "bridge";
import LeadersBoard from "./LeadersBoard/LeadersBoard";

/**
 * "Лицо" приложения. С этого компонента в приложении начинается весь визуал.
 * @type {React.NamedExoticComponent<object>}
 */

const App = memo(() => {
  const { data, loading } = useQuery<ClosestLobbyQuery>(closestLobbyQuery, {
    fetchPolicy: "network-only"
  });

  const [getLeaderBox, getLeaderBoxMeta] = useLazyQuery<
    LobbyLeaderboardQuery,
    LobbyLeaderboardQuery.Arguments
  >(lobbyLeaderboardQuery);

  useEffect(() => {
    if (data?.closestLobby?.id) {
      getLeaderBox({
        variables: {
          lobbyId: data.closestLobby.id
        }
      });
    }
  }, [data]);

  if (loading) return <AppLoadingView />;

  if (data?.closestLobby === null || undefined) return <DummyPage />;
  console.log(data?.closestLobby);

  if (
    getLeaderBoxMeta.data?.lobbyLeaderboard[0]?.name &&
    getLeaderBoxMeta.data.lobbyLeaderboard.length > 0
  ) {
    return <LeadersBoard data={getLeaderBoxMeta.data.lobbyLeaderboard} />;
  }

  return (
    <>
      <Game
        raceId={data?.closestLobby?.id}
        isHelding={data?.closestLobby?.isLaunched}
      />
    </>
  );
});

export default App;

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "../../theme/types";
import { ReactComponent as TopDecor } from "../../assets/img/rating_top.svg";
import { ReactComponent as BottomDecor } from "../../assets/img/rating_bottom.svg";
import { formatTime } from "../../utils/formateTime";

const styles = makeStyles((theme: Theme) => ({
  ratingTitle: {
    color: theme.palette.blue,
    fontSize: theme.typography.titleFontSize,
    textAlign: "center"
  },
  ratingAvaContainer: {
    width: "123px",
    height: "123px",
    borderRadius: "50%",
    overflow: "hidden",
    backgroundColor: "white",
    marginRight: "15px",
    boxSizing: "border-box",
    padding: "3px",
    flex: "0 0 123px",
    "& img": {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      objectFit: "cover",
      objectPosition: "center"
    }
  },
  ratingList: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "center",
    "& li": {
      flex: "0 41%"
    }
  },
  ratingItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginBottom: "50px"
  },
  ratingNumber: {
    marginRight: "15px",
    fontSize: "80px",
    color: theme.palette.blue,
    fontWeight: theme.typography.fontWeightBold
  },
  ratingNumberFirstPlace: {
    marginRight: "15px",
    fontSize: "80px",
    color: "#FDAD67",
    fontWeight: theme.typography.fontWeightBold
  },
  ratingName: {
    color: "black",
    fontSize: "30px",
    marginBottom: "3px",
    fontWeight: theme.typography.fontWeightMedium
  },
  ratingTime: {
    fontSize: "25px",
    color: "#9690EA",
    marginBottom: "1px",
    fontWeight: theme.typography.fontWeightBold
  },
  ratingSpeed: {
    fontSize: "23px",
    color: theme.palette.blue,
    fontWeight: theme.typography.fontWeightMedium
  }
}));

interface ILeadersBoardProps {
  data: {
    costumeColor: string;
    name: string;
    placement: number;
    profileImageUrl: string;
    time: number;
    speed: number;
  }[];
}
const LeadersBoard = (props: ILeadersBoardProps) => {
  const classes = styles();
  const sortedArray = props.data.sort((a, b) => {
    return a.placement - b.placement;
  });
  return (
    <>
      <TopDecor />

      <h1 className={classes.ratingTitle}>Турнирная таблица</h1>
      <ul className={classes.ratingList}>
        {sortedArray.map(
          (
            { costumeColor, name, placement, profileImageUrl, time, speed },
            i
          ) => {
            const [firstName, lastName] = name.split(" ");
            return (
              <>
                <li className={classes.ratingItem}>
                  <span
                    className={
                      placement === 1
                        ? classes.ratingNumberFirstPlace
                        : classes.ratingNumber
                    }
                  >
                    {i + 1}
                  </span>
                  <div
                    style={{
                      border: i === 0 ? `3px solid #FDAD67` : `3px solid white`
                    }}
                    className={classes.ratingAvaContainer}
                  >
                    <img src={profileImageUrl} alt={name}></img>
                  </div>
                  <div>
                    <div className={classes.ratingName}>
                      {`${firstName} ${lastName}`}
                    </div>
                    <div className={classes.ratingTime}>{`${formatTime(
                      time
                    )}`}</div>
                    <div className={classes.ratingSpeed}>{`${speed} км/ч`}</div>
                  </div>
                </li>
              </>
            );
          }
        )}
      </ul>

      <BottomDecor style={{ position: "absolute", bottom: "0" }} />
    </>
  );
};

export default LeadersBoard;

import React, { useEffect } from "react";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import { Theme } from "../../theme/types";

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    margin: "0 150px",
    fontFamily: theme.typography.fontFamilyTT,
    height: "auto",
    alignItems: "center",
    justifyContent: "center"
  },
  questionContainer: {
    width: "100%",
    height: "189px",
    border: `2px solid ${theme.palette.darkBlue}`,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "31px",
    padding: "30px 60px",
    boxSizing: "border-box",
    fontSize: theme.typography.fontSize
  },
  questionsContainer: {
    width: "100%",
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    textAlign: "center",
    padding: "0",
    margin: "0",
    "& li": {
      flex: "0 48%",
      boxSizing: "border-box"
    }
  },
  question: {
    width: "100%",
    background: theme.palette.grey,
    borderRadius: "5px",
    margin: "0 0 31px 0",
    minHeight: "93px",
    "& p": {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "5px",
      margin: 0,
      height: "100%",
      fontSize: theme.typography.fontSize,
      color: theme.palette.blue
    }
  }
}));
interface IQuestionProps {}

const Question = (props: any) => {
  useEffect(() => {
    console.log("QUESTION RENDER!!");
  }, [props.question]);
  //const question = props.question;
  const classes = styles();
  //const answers = [...question.slice(1, question.length)];
  const getAnswers = () =>
    props.options.map((answer: string, i: number) => {
      return (
        <li key={i} className={classes.question}>
          <p>{answer}</p>
        </li>
      );
    });

  return (
    <div className={classes.container}>
      <div className={classes.questionContainer}>{props.title}</div>
      <ul className={classes.questionsContainer}>{getAnswers()}</ul>
    </div>
  );
};

export default Question;

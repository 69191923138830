import React, { useRef, useEffect, useCallback, useState } from "react";
import { makeStyles, styled } from "@material-ui/styles";
import { Theme } from "../../theme/types";
import { ReactComponent as CarImg } from "../../assets/img/car.svg";
import { ICarProps } from "./types";
import chooseColor from "../../utils/chooseColor";
import { gsap } from "gsap/all";

const styles = makeStyles<Theme>(theme => ({
  carContainer: {
    width: "35px",
    height: "35px",
    display: "inline-block",
    borderRadius: "5px",
    position: "absolute",
    top: "0px",
    left: "0px"
  },
  car: {
    width: "35px",
    height: "35px",
    display: "inline-block",
    borderRadius: "5px",
    position: "absolute",
    top: "0px",
    left: "0px",
    "& .car": {
      fill: (props: ICarProps) => chooseColor(props.carColor)[0]
    },
    "& .car_dark": {
      fill: (props: ICarProps) => chooseColor(props.carColor)[1]
    },
    "& .car__engine": {
      fill: (props: ICarProps) => chooseColor(props.carColor)[2]
    },
    "& .player": {
      fill: (props: ICarProps) => chooseColor(props.playerColor)[0]
    },
    "& .player_dark": {
      fill: (props: ICarProps) => chooseColor(props.playerColor)[2]
    }
  }
}));

const PlayerNumber = styled("div")({
  width: "29px",
  height: "29px",
  borderRadius: "5px",
  position: "absolute",
  top: "-23px",
  left: "-9px",
  background: (props: { color: string }) => props.color,
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:after": {
    content: "' '",
    width: "0",
    height: "0",
    borderStyle: "solid",
    borderWidth: " 15px 8px 0 8px",
    borderColor: (props: { color: string }) =>
      `${props.color} transparent transparent transparent`,
    top: "21px",
    position: "absolute"
  }
});

const Car = (props: ICarProps) => {
  const classes = styles(props);

  const [isFirstRender, setIsFirstRender] = useState(true);
  const carRef = useRef<any>(null);

  useEffect(() => {
    const currentDuration = 300;

    console.log("car RENDER!");
    console.log(props.animationEndHandler);

    const progress = gsap.getById(props.carId)?.progress();
    if (gsap.getById(props.carId)) {
      gsap
        .getById(props.carId)
        .kill()
        .invalidate();
    }

    const progressTarget =
      props.playerProgress === null
        ? (props.questionsQty * 100) / props.questionsQty
        : (props.playerProgress * 100) / props.questionsQty;
    //(props.playerProgress * 100) / (props.questionsQty - 1);

    const GSAP = gsap.to(carRef.current, {
      duration: currentDuration,
      paused: true,
      id: props.carId,
      ease: "power0",

      motionPath: {
        path: props.motionPath,
        align: props.motionPath,
        autoRotate: true,
        alignOrigin: [0.5, 0.5]
      }
    });

    if (isFirstRender) {
      if (props.seekTime) {
        GSAP.seek(props.seekTime);
        setIsFirstRender(false);
        return;
      }
      if (props.carId === 1 || props.carId === 2) {
        GSAP.progress(2 / 100);
      } else {
        GSAP.progress(0.1 / 100);
      }
      setIsFirstRender(false);
    } else GSAP.progress(progress).play();

    GSAP.eventCallback("onUpdate", () => {
      // console.log(GSAP.progress() * 100);

      if (GSAP.progress() * 100 >= progressTarget) {
        GSAP.timeScale(1);
      } else GSAP.timeScale(10);

      if (GSAP.progress() * 100 >= 100) {
        props.animationEndHandler();
      }
    });
  }, [props.playerProgress]);

  return (
    <>
      <div className={classes.carContainer} ref={carRef}>
        <CarImg className={classes.car} />
        <PlayerNumber color={chooseColor(props.playerColor)[0]}>
          {props.playerNumber}
        </PlayerNumber>
      </div>
    </>
  );
};

export default React.memo(Car);

import React, { PureComponent } from "react";

import AppLoadingView from "../views/AppLoadingView";
import App from "../App";
import { Provider as StoreProvider } from "react-redux";
import ThemeProvider from "../ThemeProvider";
import AppCrashView from "../views/AppCrashView";

import createReduxStore from "../../redux";
import vkBridge, {
  Insets,
  UpdateConfigData,
  VKBridgeSubscribeHandler
} from "@vkontakte/vk-bridge";
import { configActions } from "../../redux/reducers/config";
import { isUpdateConfigEvent, isUpdateInsetsEvent } from "./utils";
import { getStorageValues } from "../../utils/bridge";

import { Store } from "redux";
import { ReduxState } from "../../redux/types";
import { StorageField } from "../../types/bridge";
import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";
import config from "../../config";
import { split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";

interface IState {
  loading: boolean;
  error: string | null;
  store: Store<ReduxState> | null;
}

/**
 * Является "мозгом" приложение. Если быть более конкретным - его
 * корнем. Здесь подгружаются все необходимые для работы приложения данные,
 * а также создаются основные контексты.
 */
class Root extends PureComponent<{}, IState> {
  constructor(props: any) {
    super(props);
    this.client.link = this.link;
    this.client.queryManager.link = this.link;
  }

  public state: Readonly<IState> = {
    loading: false,
    error: null,
    store: null
  };

  // Create an http link:
  private httpLink = new HttpLink({
    uri: config.apiBaseUrl,
    headers: {
      "x-launch-params": window.location.search.slice(1)
    }
  });

  // Create a WebSocket link:
  private wsLink = new WebSocketLink({
    uri: config.websocketUrl,

    options: {
      reconnect: true,
      connectionParams: {
        launchParams: window.location.search.slice(1)
      }
    }
  });

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  private link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    this.wsLink,
    this.httpLink
  );

  private client = new ApolloClient({
    uri: config.apiBaseUrl,
    headers: {
      "x-launch-params": window.location.search.slice(1)
    }
  });

  /**
   * Переменная которая отвечает за то, что было ли отправлено событие
   * обновления конфига приложения. Необходимо в случае, когда это событие
   * успели отловить но в тот момент Redux-хранилища еще не существовало.
   * @type {null}
   */
  private initialAppConfig: UpdateConfigData | null = null;

  /**
   * Аналогично initialAppConfigSent.
   * @type {null}
   */
  private initialAppInsets: Insets | null = null;

  /**
   * Иницилизирует приложение.
   */
  private async init() {
    this.setState({ loading: true, error: null });

    let error: string | null = null;
    let store: Store<ReduxState> | null = null;

    try {
      // Здесь необходимо выполнить все асинхронные операции и получить
      // данные для запуска приложения, после чего создать хранилище Redux.
      const [storage] = await Promise.all([
        getStorageValues(...Object.values(StorageField))
      ]);

      store = createReduxStore({ storage });
    } catch (e) {
      // В случае ошибки, мы её отловим и покажем экран с ошибкой.
      const err = e as Error;
      error = err.message;
    }

    this.setState({ store, error, loading: false });
  }

  /**
   * Проверяет, является событие VKWebAppUpdateConfig или VKWebAppUpdateInsets
   * чтобы узнать каков конфиг приложения в данный момент, а также - какие
   * внутренние рамки экрана существуют.
   * @param {VKBridgeEvent<ReceiveMethodName>} event
   */
  private onVKBridgeEvent: VKBridgeSubscribeHandler = event => {
    const { store } = this.state;

    if (event.detail) {
      if (isUpdateConfigEvent(event)) {
        if (store) {
          store.dispatch(configActions.updateConfig(event.detail.data));
        } else {
          this.initialAppConfig = event.detail.data;
        }
      } else if (isUpdateInsetsEvent(event)) {
        if (store) {
          store.dispatch(configActions.updateInsets(event.detail.data.insets));
        } else {
          this.initialAppInsets = event.detail.data.insets;
        }
      }
    }
  };

  public componentDidMount() {
    // Когда компонент загрузился, мы ожидаем обновления внутренних рамок
    // и конфига приложения.

    vkBridge.subscribe(this.onVKBridgeEvent);

    // Уведомляем нативное приложение о том, что инициализация окончена.
    // Это заставит нативное приложение спрятать лоадер и показать наше
    // приложение.
    // Причина по которой мы проводим инициализацию здесь - нативное приложение
    // автоматически отправлять информацию о конфиге и внутренних рамках,
    // которая нам нужна.
    vkBridge.send("VKWebAppInit");

    // Инициализируем приложение.
    this.init();
  }

  public componentDidUpdate(prevProps: {}, prevState: Readonly<IState>) {
    const { store } = this.state;

    // Как только хранилище появилось, проверяем, были ли получены до этого
    // информация о конфиге и инсетах. Если да, то записываем в хранилище.
    // TODO: Перенести диспатчи в место где создается хранилище.
    if (prevState.store === null && store !== null) {
      if (this.initialAppConfig) {
        store.dispatch(configActions.updateConfig(this.initialAppConfig));
      }
      if (this.initialAppInsets) {
        store.dispatch(configActions.updateInsets(this.initialAppInsets));
      }
    }
  }

  public componentDidCatch(error: Error) {
    // Отлавливаем ошибку, если выше этого не произошло.
    this.setState({ error: error.message });
  }

  public componentWillUnmount() {
    // При разгрузке удаляем слушателя событий.
    vkBridge.unsubscribe(this.onVKBridgeEvent);
  }

  public render() {
    const { loading, error, store } = this.state;

    // Отображаем лоадер если приложение еще загружается.
    if (loading || !store) {
      return <AppLoadingView />;
    }

    // Отображаем ошибку если она была.
    if (error) {
      return (
        <ThemeProvider>
          <AppCrashView onRestartClick={this.init} error={error} />
        </ThemeProvider>
      );
    }

    // Отображаем приложение если у нас есть всё, что необходимо.
    return (
      <StoreProvider store={store}>
        <ApolloProvider client={this.client}>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </ApolloProvider>
      </StoreProvider>
    );
  }
}

export default Root;

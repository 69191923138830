import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "../../theme/types/theme";

const styles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    flexDirection: "column"
  },
  message: {
    color: theme.palette.blue,
    fontSize: theme.typography.titleFontSize,
    marginBottom: "5px"
  },
  additionalMessage: {
    color: theme.palette.darkBlue,
    fontSize: theme.typography.fontSize
  }
}));
const DummyPage = () => {
  const classes = styles();
  return (
    <div className={classes.root}>
      <div className={classes.message}>
        На данный момент никаких игр недоступно
      </div>
      <div className={classes.additionalMessage}>
        (Попробуйте обновить страницу или дождитесь следующей игры)
      </div>
    </div>
  );
};

export default DummyPage;

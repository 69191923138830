import { Theme } from "./types";

const defaultTheme: Theme = {
  palette: {
    darkBlue: "#4986CC",
    blue: "#3F8AE0",
    grey: "#F2F3F5"
  },

  typography: {
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontSize: 30,
    titleFontSize: 60,
    fontFamily: "-apple-system, sans-serif, Roboto, Helvetica Neue, Arial",
    fontFamilyTT: '"TT Commons", -apple-system,  Roboto, Helvetica Neue, Arial'
  }
};

export default defaultTheme;

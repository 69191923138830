export enum EColor {
  blue = "BLUE",
  green = "GREEN",
  red = "RED",
  purple = "PURPLE",
  yellow = "YELLOW"
}

export interface ICarProps {
  playerColor: EColor;
  playerNumber: number;
  carColor: EColor;
  carId: number;
  motionPath: string;
  playerProgress?: number | null;
  animationEndHandler?: () => void;
  seekTime?: number | null;
  questionsQty: number;
}

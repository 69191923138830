interface IConfig {
  apiBaseUrl: string;
  websocketUrl: string;
}

// Here we are loading config depending on environment variables. Dont
// forget that variables starting only with REACT_APP will be loaded
// by react-scripts
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL;

if (!apiBaseUrl) {
  throw new Error("API base url not passed");
}
if (!websocketUrl) {
  throw new Error("API websocket url not passed");
}

const config: IConfig = {
  apiBaseUrl,
  websocketUrl
};

export default config;

import React, { useState, useEffect } from "react";
import { ReactComponent as RaceSVG } from "../../assets/img/race_test.svg";
import { IRaceProps } from "./types";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/all";
import Question from "../Question";
import PlayersInfo from "../PlayersInfo";
import Car from "../Car";

gsap.registerPlugin(MotionPathPlugin);

const Race = (props: IRaceProps) => {
  const [playersProgress, setPlayerProgress] = useState([0, 0, 0, 0]);
  //кол-во вопросов в игре
  const questionsQty = 20;

  useEffect(() => {
    for (let i = 1; i <= 4; i++) {
      gsap.getById(i)?.resume();
    }
  }, []);

  useEffect(() => {}, [playersProgress]);

  return (
    <>
      <PlayersInfo data={props.users} />
      <RaceSVG
        style={{
          margin: "auto",
          display: "block",
          marginBottom: "10px",
          width: "100%"
        }}
      />
      {props.users.map(
        ({ costumeColor, cartColor, timeLeft, question }: any, i: number) => {
          console.log(question);

          const positionOnRace = i > 1 ? 2 : 1;
          const path =
            (i + 1) % 2 === 0
              ? "svg .race__path_second"
              : "svg .race__path_first";
          return (
            <Car
              key={i}
              playerColor={costumeColor}
              carColor={cartColor}
              playerNumber={i + 1}
              carId={i + 1}
              motionPath={path}
              playerProgress={question}
              seekTime={props.isHelding ? 300 - timeLeft / 1000 : null}
              animationEndHandler={props.animationEndHandler}
              questionsQty={questionsQty}
            />
          );
        }
      )}

      <Question title={props.question.title} options={props.question.options} />
    </>
  );
};

export default Race;
